var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hb-star-rating",
      class: {
        "hb-star-rating--editable": _vm.editable
      }
    },
    [
      _vm._l(_vm.max, function(point, key) {
        return [
          _c(
            "div",
            {
              key: key,
              staticClass: "star-rating--item",
              style: _vm.itemStyle(point),
              on: {
                click: function() {
                  return _vm.setValue(point)
                }
              }
            },
            [
              _vm.isHalf(point)
                ? [
                    _c("icon", {
                      attrs: {
                        src: "img/star/star-half.png",
                        width: _vm.size + "px",
                        height: _vm.size + "px"
                      }
                    })
                  ]
                : _vm.isActive(point) || !_vm.outline
                ? [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: _vm.size,
                          height: _vm.size,
                          viewBox: "0 0 36 36"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "m18.038 0-5.595 11.822L0 13.683l8.975 9.243-2.161 13.007 11.142-6.11L29.063 36l-2.088-13.02L36 13.794l-12.433-1.937z",
                            "fill-rule": "evenodd",
                            fill: _vm.isActive(point)
                              ? _vm.activeColor
                              : _vm.defaultColor
                          }
                        })
                      ]
                    )
                  ]
                : [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: _vm.size,
                          height: _vm.size,
                          viewBox: "0 0 80 80"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "m40.07 5.291 10.77 23.095L75.324 32.2 57.543 50.303l4.087 25.479-21.72-12.08L18.12 75.65l4.23-25.457-17.683-18.21 24.504-3.665L40.069 5.291z",
                            stroke: _vm.defaultColor,
                            "stroke-width": "4.5",
                            fill: "none",
                            "fill-rule": "evenodd"
                          }
                        })
                      ]
                    )
                  ]
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }