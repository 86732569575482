<template>
    <div
        class="hb-star-rating"
        :class="{
            'hb-star-rating--editable': editable,
        }"
    >
        <template v-for="(point, key) in max">
            <div :key="key" class="star-rating--item" :style="itemStyle(point)" @click="() => setValue(point)">
                <template v-if="isHalf(point)">
                    <icon src="img/star/star-half.png" :width="`${size}px`" :height="`${size}px`" />
                </template>
                <template v-else-if="isActive(point) || !outline">
                    <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 36 36">
                        <path
                            d="m18.038 0-5.595 11.822L0 13.683l8.975 9.243-2.161 13.007 11.142-6.11L29.063 36l-2.088-13.02L36 13.794l-12.433-1.937z"
                            fill-rule="evenodd"
                            :fill="isActive(point) ? activeColor : defaultColor"
                        />
                    </svg>
                </template>
                <template v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 80 80">
                        <path
                            d="m40.07 5.291 10.77 23.095L75.324 32.2 57.543 50.303l4.087 25.479-21.72-12.08L18.12 75.65l4.23-25.457-17.683-18.21 24.504-3.665L40.069 5.291z"
                            :stroke="defaultColor"
                            stroke-width="4.5"
                            fill="none"
                            fill-rule="evenodd"
                        />
                    </svg>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import Icon from '@/components/common/Icon.vue'

export default {
    components: {
        Icon,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 5,
        },
        size: {
            type: Number,
            default: 36,
        },
        gutter: {
            type: Number,
            default: 6,
        },
        outline: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        activeColor() {
            return '#f5d03a'
        },
        defaultColor() {
            if (this.outline) {
                return this.activeColor
            }

            return '#bcbcbc'
        },
    },
    methods: {
        isActive(point: number) {
            return this.value >= point
        },
        isHalf(point: number) {
            const diff = point - this.value
            return diff > 0 && diff < 1
        },
        itemStyle(point) {
            if (point === this.max) {
                return undefined
            }

            return {
                marginRight: `${this.gutter}px`,
            }
        },
        setValue(point: number) {
            if (this.editable) {
                this.$emit('input', point)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.hb-star-rating {
    display: flex;

    &.hb-star-rating--editable {
        .star-rating--item {
            cursor: pointer;
        }
    }
}
</style>
